import React, { useEffect } from "react";
import TopNav from "../Components/TopNav";
import Nav from "../Components/Nav";
import SimpleLanding from "../Components/SimpleLanding";
import SimpleFeature from "../Components/SimpleFeature";
import Imprest from "../Components/Imprest";
import StatsBanner from "../Components/StatsBanner";
import FAQ from "../Components/FAQ";
import Feedbacks from "../Components/Feedbacks";
import PackgesOffers from "../Components/PackgesOffers";
import Gallery from "../Components/Gallery";
import Blog from "../Components/Blog";
import FreeConstlate from "../Components/FreeConstlate";
import FooterAndLoader from "../Components/FooterAndLoader";
import { useParams } from "react-router-dom";
import { loadAllScritps, unloadAllScritps } from "../scritpsAll";
import DownloadApp from "../Components/DownloadApp";

function Home({ isHome }) {
  const params = useParams();
  useEffect(() => {
    try {
      params && loadAllScritps();
    } catch {
      console.error("Failed to load scripts");
    }
    return () => {
      unloadAllScritps();
    };
  }, [params]);
  return (
    <>
      <>
        <TopNav />
        <Nav />
        {isHome && <SimpleLanding />}
        {isHome && <SimpleFeature />}

        <Imprest detalies={false} />
        <StatsBanner />
        <FAQ />
        {/* <Feedbacks /> */}
        {/* {isHome && <PackgesOffers />} */}
        {/* {isHome && <Gallery />} */}
        <DownloadApp />
        <Blog />
        <FreeConstlate />
        <FooterAndLoader />
      </>
    </>
  );
}

export default Home;

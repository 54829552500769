import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { io } from "socket.io-client";
import { PublicRequest } from "../Requests/Requests";
import Loader from "../Components/Loader";
import DownloadApp from "../Components/DownloadApp";

const Container = styled.div`
  min-height: 100vh;
  background-color: #f5f5f5;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
`;

const PetImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentCard = styled.div`
  background: white;
  margin: -40px 16px 0;
  border-radius: 20px;
  position: relative;
  z-index: 10;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
`;

const PetName = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 4px 0;
`;

const Location = styled.p`
  color: #6b7280;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Button = styled.button`
  padding: ${(props) => (props.size === "small" ? "8px 12px" : "10px 16px")};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s;

  ${(props) =>
    props.variant === "outline"
      ? `
      background: transparent;
      border: 1px solid #e5e7eb;
      color: #374151;
      &:hover {
        background: #f3f4f6;
      }
    `
      : `
      background: #ec4899;
      border: none;
      color: white;
      &:hover {
        background: #db2777;
      }
    `}
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 32px;
`;

const StatCard = styled.div`
  background: linear-gradient(135deg, #ec4899 0%, #db2777 100%);
  border-radius: 16px;
  padding: 16px;
  text-align: center;
  color: white;
`;

const StatValue = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 4px 0;
`;

const StatLabel = styled.p`
  font-size: 14px;
  opacity: 0.8;
  margin: 0;
`;

const OwnerCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #f9fafb;
  border-radius: 12px;
  margin-bottom: 24px;
`;

const OwnerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const OwnerImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
`;

const OwnerName = styled.p`
  font-weight: 600;
  margin: 0 0 4px 0;
`;

const OwnerRole = styled.p`
  font-size: 14px;
  color: #6b7280;
  margin: 0;
`;

const ContactButtons = styled.div`
  display: flex;
  gap: 16px;
`;

const IconButton = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    background: #f3f4f6;
  }
`;

const Bio = styled.p`
  color: #6b7280;
  line-height: 1.6;
  margin: 0;
`;

const Modal = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  padding: 16px;
  z-index: 50;
`;

const ModalCard = styled.div`
  background: white;
  border-radius: 16px;
  width: 100%;
  max-width: 480px;
  overflow: hidden;
`;

const ModalHeader = styled.div`
  padding: 24px;
  text-align: center;
`;

const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin: 16px 0 0 0;
`;

const ModalContent = styled.div`
  padding: 0 24px 24px;
`;

const ModalText = styled.p`
  text-align: center;
  margin-bottom: 24px;
  color: #4b5563;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 12px;

  &:focus {
    outline: none;
    border-color: #ec4899;
    box-shadow: 0 0 0 2px rgba(236, 72, 153, 0.1);
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;
const PetDetails = () => {
  const [isModalVisible, setModalVisible] = useState(true);
  const [info, setInfo] = useState(null);
  const [locationSent, setLocationSent] = useState(false);
  const [founderInfo, setFounderInfo] = useState({});
  const [coords, setCoords] = useState(null);
  const [error, setError] = useState(null);
  const [showInformationForm, setShowInformationForm] = useState(false);
  const [scanId, setScanId] = useState("");
  const ioRef = useRef();
  const params = useParams();

  const [flowData, setFlowData] = useState({
    title: "Hello, I have lost my family.",
    content:
      "Please, can you accept the location request? I told my family I'm fine. :(",
    stage: 1,
  });

  const pushNotificationSend = useCallback(
    (data = {}) => {
      ioRef.current = io("https://petioapi-production.up.railway.app/");
      ioRef.current?.emit("newScan", { id: params?.id, data });
    },
    [params?.id]
  );

  useEffect(() => {
    const fetchPetInfo = async () => {
      try {
        const response = await PublicRequest.get(`/auth/getuser/${params.id}`);
        setInfo(response.data);
      } catch (err) {
        setError("Failed to fetch pet information");
      }
    };
    fetchPetInfo();
  }, [params.id]);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const response = await PublicRequest.post("/newScan", {
            location: {
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
            },
            petOwnerId: params.id,
          });

          setScanId(response.data);
          stageHandler(1);
          setLocationSent(true);
          pushNotificationSend();
          setCoords(position.coords);
        } catch (err) {
          setError("Failed to send location");
        }
      },
      (err) => setError(err.message)
    );
  };

  const handelInfoChange = (f, v) => {
    setFounderInfo({ ...founderInfo, [f]: v });
  };

  const stageHandler = (stageLevel) => {
    switch (stageLevel) {
      case 1:
        setFlowData({
          content:
            "Thanks for sending your location to my family! Now, if you want to help me more, you can send your contact information to my family. Click 'Next.'",
          title: "We've Notified the Owner",
          stage: 2,
        });
        break;
      case 2:
        setFlowData({
          content: "Fill This Fileds with your mobile number and name.",
          title: "Woow Let My Onwer Know you",
          stage: 3,
        });
        break;
      default:
        break;
    }
  };

  const petImageAndStage = () => {
    switch (true) {
      case flowData.stage === 1 && info?.petInfo?.pet === "dog":
        return "/images/lostSad.png";
      case flowData.stage >= 2 && info?.petInfo?.pet === "dog":
        return "/images/thanksDog.png";
      case flowData.stage === 1 && info?.petInfo?.pet === "cat":
        return "/images/lostCat.png";
      case flowData.stage >= 2 && info?.petInfo?.pet === "cat":
        return "/images/ThanksCat.png";
      default:
        return "no type";
    }
  };
  const showFormAndNextStage = () => {
    setShowInformationForm(true);
    stageHandler(2);
  };
  const sendLocationWithInfo = async () => {
    if (!founderInfo.founderName || !founderInfo.founderNumber) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const response = await PublicRequest.post("/newScan", {
        location: { latitude: coords.latitude, longitude: coords.longitude },
        petOwnerId: params.id,
        ...founderInfo,
      });
      setScanId(response.data);
      setModalVisible(false);
      setLocationSent(true);
      pushNotificationSend({ ...founderInfo });
    } catch (err) {
      setError("Failed to send information");
    }
  };
  if (!info) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (!info?.petInfo) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          padding: "16px",
        }}
      >
        <img
          src="/images/catmad.jpeg"
          alt="Cat"
          style={{
            width: "256px",
            height: "256px",
            objectFit: "cover",
            borderRadius: "50%",
            marginBottom: "24px",
          }}
        />
        <h3 style={{ textAlign: "center", marginBottom: "16px" }}>
          Please update pet information from the PetIO app
        </h3>
        <p style={{ marginBottom: "16px" }}>Download app 👇</p>
        <DownloadApp />
      </div>
    );
  }

  console.log(flowData);

  return (
    <Container>
      <Modal show={isModalVisible}>
        <ModalCard>
          <ModalHeader>
            <img src={petImageAndStage()} alt="Pet" style={{ width: "100%" }} />
            <ModalTitle>{flowData.title}</ModalTitle>
          </ModalHeader>
          <ModalContent>
            <ModalText>{flowData.content}</ModalText>

            {showInformationForm && (
              <div
                style={{
                  justifyItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Input
                  name="founderName"
                  placeholder="Your Name"
                  onChange={(e) =>
                    handelInfoChange(e.target.name, e.target.value)
                  }
                />
                <Input
                  name="founderNumber"
                  type="tel"
                  placeholder="Your Phone Number"
                  onChange={(e) =>
                    handelInfoChange(e.target.name, e.target.value)
                  }
                />
              </div>
            )}

            <ModalButtons>
              <Button variant="outline" onClick={() => setModalVisible(false)}>
                Close
              </Button>
              <Button
                onClick={() => {
                  if (flowData.stage === 1) getLocation();
                  else if (flowData.stage === 2) showFormAndNextStage();
                  else sendLocationWithInfo();
                }}
              >
                {flowData.stage === 1
                  ? "Send Location"
                  : flowData.stage === 2
                  ? "Next"
                  : "Send"}
              </Button>
            </ModalButtons>
          </ModalContent>
        </ModalCard>
      </Modal>

      <ImageContainer>
        <PetImage src={info.petInfo.image} alt={info.petInfo.name} />
      </ImageContainer>

      <ContentCard>
        <Header>
          <div>
            <PetName>{info.petInfo.name}</PetName>
            <Location>
              <i className="fi fi-rr-marker" /> {info.location}
            </Location>
          </div>
          <ButtonGroup>
            <Button
              variant="outline"
              size="small"
              onClick={() =>
                window.open(
                  `https://www.google.com/maps?q=${info?.petInfo.daynamicLocation?.latitude},${info?.petInfo.daynamicLocation?.longitude}`,
                  "_blank"
                )
              }
            >
              <i className="fi fi-rr-marker" /> View Location
            </Button>
            <Button size="small" onClick={() => setModalVisible(true)}>
              <i className="fi fi-rr-paper-plane" /> Send Location
            </Button>
          </ButtonGroup>
        </Header>

        <StatsGrid>
          <StatCard>
            <StatValue>{info.petInfo.sex}</StatValue>
            <StatLabel>Sex</StatLabel>
          </StatCard>
          <StatCard>
            <StatValue>2 Years</StatValue>
            <StatLabel>Age</StatLabel>
          </StatCard>
          <StatCard>
            <StatValue>{info.petInfo.weight}</StatValue>
            <StatLabel>Weight</StatLabel>
          </StatCard>
        </StatsGrid>

        <OwnerCard>
          <OwnerInfo>
            <OwnerImage src={info.profilePic} alt={info.name} />
            <div>
              <OwnerName>{info.name}</OwnerName>
              <OwnerRole>Owner</OwnerRole>
            </div>
          </OwnerInfo>
          <ContactButtons>
            <IconButton href={`tel:${info.phone}`} color="#ec4899">
              <i className="fi fi-br-phone-flip" style={{ fontSize: "20px" }} />
            </IconButton>
            <IconButton href={`sms:${info.phone}`} color="#60a5fa">
              <i
                className="fi fi-rr-comment-dots"
                style={{ fontSize: "20px" }}
              />
            </IconButton>
          </ContactButtons>
        </OwnerCard>

        <Bio>{info.petInfo.bio}</Bio>
      </ContentCard>
    </Container>
  );
};

export default PetDetails;
